import { Link as GatsbyLink } from "gatsby"
import React from "react"
import cx from "classnames"
import styles from "./styles.module.css"
import { Link as ScrollLink } from "react-scroll"

const style = {
  color: "inherit",
  textDecoration: "inherit",
}

const Link = ({ smooth, href, className, style: givenStyle, ...props }) => (
  <div className={cx(styles.Link, className)} style={givenStyle}>
    {smooth ? (
      <ScrollLink {...props} smooth />
    ) : href || props.target ? (
      <a {...props} href={href || props.to} style={style}>
        {props.children}
      </a>
    ) : (
      <GatsbyLink style={style} {...props} />
    )}
  </div>
)

export default Link
