import React from "react"
import PropTypes from "prop-types"
import whiteSvgLogo from "src/images/whiteLogo.svg"
import coloredSvgLogo from "src/images/coloredLogo.svg"
import whitePngLogo from "src/images/whiteLogo.png"
import coloredPngLogo from "src/images/coloredLogo.png"
import { detect } from "detect-browser"
import styles from "./styles.module.css"

const Logo = ({ white }) => {
  const browser = detect()
  const isIE = browser.name === "ie"

  const whitelogo = isIE ? whitePngLogo : whiteSvgLogo
  const coloredLogo = isIE ? coloredPngLogo : coloredSvgLogo

  return (
    <img
      className={styles.Logo}
      src={white ? whitelogo : coloredLogo}
      alt="Turtle Tonic"
    />
  )
}

Logo.propTypes = {
  white: PropTypes.bool,
}

export default Logo
