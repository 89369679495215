import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import cx from "classnames"

import Header from "src/components/shared/Header"
import Footer from "src/components/shared/Footer"
import styles from "./styles.module.css"
import Information from "../Information"

export const Inner = (props) => (
  <div
    className={cx(styles.Inner, { [styles.Small]: props.small })}
    {...props}
  />
)

const Layout = ({
  bannerImage,
  withIllustration,
  withoutHeader,
  white,
  children,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div
          className={cx(styles.Layout, { [styles.WithIllu]: withIllustration })}
        >
          <Information />
          <Inner>
            {!withoutHeader && (
              <Header siteTitle={data.site.siteMetadata.title} white={white} />
            )}

            {/* {withIllustration && (
              <TopIllustration
                src={Turtle}
                alt={data.site.siteMetadata.title}
                className={styles.TopIllustration}
              />
            )} */}
          </Inner>
          <div>
            <main>{children}</main>
          </div>

          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  withIllustration: PropTypes.bool,
  white: PropTypes.bool,
  bannerImage: PropTypes.node,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  bannerImage: "turtle",
}

export default Layout
