import React from "react"
import cx from "classnames"
import ReactModal from "react-modal"
import styles from "./styles.module.css"
import Close from "src/images/close.svg"

const Modal = ({
  className,
  overlayClassName,
  onClose,
  children,
  ...props
}) => {
  return (
    <ReactModal
      className={cx(styles.Modal, className)}
      overlayClassName={cx(styles.Overlay, className)}
      onRequestClose={onClose}
      closeTimeoutMS={500}
      ariaHideApp={false}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...props}
    >
      <div
        className={styles.CloseButton}
        onClick={onClose}
        onKeyDown={(e) => (e.keyCode === 27 ? onClose : null)}
        role="button"
        tabIndex={0}
      >
        <img src={Close} alt="Fermer" />
      </div>
      {children}
    </ReactModal>
  )
}

export default Modal
