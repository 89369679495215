import React from "react"
import styles from "./styles.module.css"
import cx from "classnames"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import { Input } from "src/components/shared/Form"

const Newsletter = ({ className }) => (
  <div id="mc_embed_signup" className={cx(styles.Container, className)}>
    <Typography.h1 className={styles.Title}>
      Abonnez-vous à la newsletter
    </Typography.h1>
    <form
      className={styles.Form}
      action="https://turtletonic.us20.list-manage.com/subscribe/post?u=350c634576a225b336dbf2ba2&amp;id=1dfdc5d80a"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      novalidate
    >
      <div id="mc_embed_signup_scroll">
        <div class="mc-field-group">
          <label for="mce-EMAIL">
            <Input
              id="email"
              name="email"
              type="text"
              placeholder="Entrez votre adresse mail"
              name="EMAIL"
              id="mce-EMAIL"
            />
          </label>

          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
        </div>
        <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
          <input
            type="text"
            name="b_350c634576a225b336dbf2ba2_1dfdc5d80a"
            tabindex="-1"
            value=""
          />
        </div>
        <Button
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          className={styles.SendButton}
        >
          Envoyer
        </Button>
      </div>
    </form>
  </div>
)

export default Newsletter
