import React, { useState } from "react"
import Typography from "src/components/shared/Typography"
import Modal from "../Modal"
import { Inner } from "../Layout"
import styles from "./styles.module.css"
import { useStaticQuery, graphql } from "gatsby"

const informationQuery = graphql`
  query InformationTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "information" } }) {
      html
      frontmatter {
        shouldDisplay
        intro
        title
      }
    }
  }
`

const InformationTemplate = ({
  preview,
  shouldDisplay,
  title,
  intro,
  html,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const onClose = () => setModalVisible(false)

  return (
    shouldDisplay && (
      <div className={styles.Info}>
        <Inner>
          <div className={styles.InfoInner}>
            <div>
              {intro}{" "}
              <span
                onClick={() => setModalVisible(true)}
                role="button"
                onKeyDown={(e) => null}
                className={styles.InfoReadMore}
                tabIndex={0}
              >
                En savoir plus...
              </span>
            </div>
          </div>
        </Inner>
        <Modal onClose={onClose} isOpen={modalVisible}>
          <Typography.h3 className={styles.ModalTitle}>{title}</Typography.h3>
          <div className={styles.ModalContent}>
            {!!preview ? (
              <Typography.p primary>{html}</Typography.p>
            ) : (
              <div
                className={styles.Paragraph}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </div>
        </Modal>
      </div>
    )
  )
}

export default () => {
  const data = useStaticQuery(informationQuery)
  const {
    markdownRemark: {
      frontmatter: { shouldDisplay, intro, title },
      html,
    },
  } = data

  return (
    <InformationTemplate
      shouldDisplay={shouldDisplay}
      title={title}
      intro={intro}
      html={html}
    />
  )
}
