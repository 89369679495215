import React from "react"
import Logo from "src/components/shared/Logo"
import Link from "src/components/shared/Link"
import Newsletter from "./Newsletter/index"
import Typography from "src/components/shared/Typography"
// import PhoneBook from "src/images/icon-phone-book.svg"
// import Email from "src/images/icon-email.svg"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"

const Footer = () => (
  <footer className={styles.Footer}>
    <Inner>
      <Newsletter className={styles.Newsletter} />
      <div className={styles.FooterContent}>
        <div className={styles.LogoSection}>
          <Link to="/">
            <Logo white />
          </Link>
          <p>
            Remise en forme, aquagym, aquabike et cours de natation à
            Parentis-en-Born.
          </p>
        </div>
        <div>
          <Typography.h3 className={styles.ListTitle}>
            Turtle Tonic
          </Typography.h3>
          <ul>
            <li className={styles.ListItem}>
              <Link to="/">Accueil</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/planning">Planning</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/cours">Les cours</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/tarifs">Les tarifs</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/reservation">Réserver</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/contact">Contactez-nous</Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography.h3 className={styles.ListTitle}>Contact</Typography.h3>
          <ul>
            <li className={styles.ListItem}>
              {/* <img className={styles.Icon} src={PhoneBook} /> */}
              {"   "}
              <Link href="tel:0768678119">07.68.67.81.19</Link>
            </li>
            <li className={styles.ListItem}>
              {/* <img className={styles.Icon} src={Email} /> */}
              {"   "}
              <Link href="mailto:contact@turtletonic.com">
                contact@turtletonic.com
              </Link>
            </li>
            <li className={styles.ListItem}>
              <div className={styles.Addresses}>
                <Typography.p className={styles.Address}>
                  <Link
                    target="_blank"
                    href="https://www.google.com/maps/place/189+Avenue+Mar%C3%A9chal+Foch,+40160+Parentis-en-Born/@44.3521157,-1.0763651,17z/data=!3m1!4b1!4m5!3m4!1s0xd547cdea2760ed9:0xe1c260e2b0d82215!8m2!3d44.3521157!4d-1.0741764"
                  >
                    189 Avenue du Maréchal Foch
                    <br />
                    40160 <b>Parentis-en-Born</b>
                  </Link>
                </Typography.p>
              </div>
            </li>
            <li className={styles.ListItem}>
              <Link target="_blank" href="https://www.facebook.com/TurtleTonic">
                Facebook
              </Link>
            </li>
            <li className={styles.ListItem}>
              <Link
                target="_blank"
                href="https://www.instagram.com/turtle_tonic/"
              >
                Instagram
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <Typography.p className={styles.Copyright}>
        Copyright Turtle Tonic - {new Date().getFullYear()}
      </Typography.p>
    </Inner>
  </footer>
)

export default Footer
