import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Logo from "src/components/shared/Logo"
import Link from "src/components/shared/Link"
import Button from "src/components/shared/Button"
import styles from "./styles.module.css"

const Header = ({ siteTitle, white }) => (
  <header className={cx(styles.Header, { [styles.White]: white })}>
    <Link to="/">
      <Logo white={white} />
    </Link>
    <ul className={styles.List}>
      <li className={styles.ListItem}>
        <Link to="/cours">Cours</Link>
      </li>
      <li className={styles.ListItem}>
        <Link to="/planning">Planning</Link>
      </li>
      <li className={styles.ListItem}>
        <Link to="/reservation">Réservation</Link>
      </li>
      <li className={styles.ListItem}>
        <Link to="/tarifs">Tarifs</Link>
      </li>
      <li className={styles.ListItem}>
        <Button white={white} className={styles.ContactButton} href="/contact">
          Contactez-nous
        </Button>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  white: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  white: false,
}

export default Header
