import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Link from "src/components/shared/Link"
import styles from "./styles.module.css"

const Button = ({
  href,
  children,
  white,
  bordered,
  type,
  style,
  className,
  ...props
}) =>
  type === "submit" ? (
    <button
      style={style}
      className={cx(
        styles.Button,
        { [styles.White]: white, [styles.Bordered]: bordered },
        className
      )}
    >
      <div>{children}</div>
    </button>
  ) : (
    <div>
      <Link
        className={cx(
          styles.Button,
          styles.Link,
          { [styles.White]: white, [styles.Bordered]: bordered },
          className
        )}
        to={href}
        style={style}
        {...props}
      >
        <span>{children}</span>
      </Link>
    </div>
  )

Button.propTypes = {
  href: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  white: PropTypes.bool,
  bordered: PropTypes.bool,
  className: PropTypes.string,
}

export default Button
